<template>
  <div class="equity-ecology-box grid h-100 grid-cols-2 grid-rows-2 gap-4">
    <div class="card" v-for="(item, index) in dataList" :key="index">
      <img class="bg-img" :src="item.bgImg" />
      <div class="content">
        <!-- <img :src="item.icon" /> -->
        <p class="title">{{ item.title }}</p>
        <p class="desc">{{ item.desc }}</p>
      </div>
    </div>
  </div>
</template>

<script>
// 两行两列
export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="less" scoped>
.equity-ecology-box {
  .card {
    height: 289px;
    position: relative;
    overflow: hidden;
    .bg-img {
      width: 100%;
      height: 100%;
      transition-duration: 300ms;
    }
    .bg-img:hover {
      transform: scale(1.1);
      transition-duration: 300ms;
    }
    .content {
      width: 260px;
      height: 289px;
      // background: rgba(33, 67, 174, 0.8);
      // backdrop-filter: blur(6px);
      padding: 48px 30px 0 49px;
      position: absolute;
      top: 0;
      img {
        max-width: 48px;
        max-height: 45px;
      }
      .title {
        color: rgba(34, 78, 219, 1);
        font-size: 16px;
        margin: 15px 0 16px 0;
        font-weight: 700;
      }
      .desc {
        width: 182px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
</style>
