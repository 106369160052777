<template>
  <div class="virtualmall">
    <!-- 顶部图 -->
    <div class="header-banner">
      <div class="container mx-auto title-box">
        <h1 class="banner-one-title">数字权益商品全链路服务应用</h1>
        <div class="banner-two flex">
          <button class="btn">积分运营工具</button>
          <button class="btn">商城服务搭建</button>
          <button class="btn">营销活动策划</button>
          <button class="btn">福利积分商城</button>
        </div>
        <button class="btn-line">立即咨询</button>
      </div>
    </div>
    <!-- 运营服务保障 -->
    <div class="virtualmall-introduce">
      <div class="container mx-auto">
        <h2 class="two-leave-title">运营服务保障</h2>
        <div class="content">
          <div class="left">
            <SetUp :activeIndex.sync="stepActiveIndex" />
          </div>
          <div class="right">
            <img class="wow animate__animated animate__fadeInUp" :src="stepImgList[stepActiveIndex].url" />
          </div>
        </div>
      </div>
    </div>
    <!-- 运营活动 -->
    <div class="virtualmall-ecology">
      <div class="container mx-auto">
        <h2 class="two-leave-title">深度打造弹性员工福利解决方案</h2>
        <p class="desc">
          有效提高企业员工福利管理效率、控制福利支出，以用户忠诚度为导向，打造专业积分营销活动方案。通过与节日时节、时事热点、行业热点等相结合，不断更新创意，提高对用户的吸引力，制定丰富多样的活动、促销、游戏和促活机制等，给用户带来更多乐趣，提高用户活跃度。
        </p>
        <div class="fore-card">
          <ForeCard :dataList="foreCardList" />
        </div>
      </div>
    </div>
    <!-- 使用场景 -->
    <div class="use-scenarios">
      <div class="container mx-auto scenarios">
        <h2 class="two-leave-title">创新福利管理理念，全面提升福利价值</h2>
        <p class="desc">
          通过互联网思维平台化管理，深度挖掘员工福利需求，实现企业员工福利管理需要，将企业福利、激励政策、企业文化、规章制度等以积分形式，通过线上科学、规范、系统的进行管理和呈现，同时配套专属福利平台，实时兑换，便捷高效。
          <br />
          结合员工福利不断完善企业激励机制，将员工的综合能力与工作表现以积分体现，通过各种福利形式与积分排名相结合，灵活发放员工福利从而全方位调动员工的主观能动性。提供专业一对一定制服务，搭建企业专属福利商城，企业可以从福利商品库内挑选多样化的福利产品，自行搭配以满足实际需求。
          <br />
          实现福利主题及场景全面覆盖，围绕用户生活服务打造各类活动环节。根据企业不同的福利场景需求，有针对性的定制福利选品方案。各大热销及节日福利专题商品更有针对性的满足员工如端午、中秋、春节、生日、女神、清凉、温暖、亲子、团建、认可等各式福利场景需求。
        </p>
      </div>
    </div>
    <!-- 合作品牌 -->
    <div class="brand">
      <div class="container mx-auto scenarios">
        <h2 class="two-leave-title">合作品牌</h2>
      </div>
      <div class="container mx-auto">
        <HomeBrandSwiper />
      </div>
    </div>
  </div>
</template>

<script>
// components
import ForeCard from '@/components/common/ForeCard';
import SetUp from '@/components/common/SetUp';
import HomeBrandSwiper from '@/components/common/HomeBrandSwiper';

export default {
  components: {
    ForeCard,
    SetUp,
    HomeBrandSwiper
  },
  data() {
    return {
      foreCardList: [
        {
          bgImg: require('@/assets/img/virtualmall/vi-bg-one.jpg'),
          title: '有效福利整合',
          desc: '作为强大的福利平台，弹性福利为特定情况下不同福利项目的整合和优化提供了极大的空间和灵活性，增强了企业与员工之间的沟通与互动。'
        },
        {
          bgImg: require('@/assets/img/virtualmall/vi-bg-two.jpg'),
          title: '管控福利成本',
          desc: '在福利成本既定条件下，企业长期福利支出得到有效控制，必要时可选择和员工共担福利价格上涨的成本。'
        },
        {
          bgImg: require('@/assets/img/virtualmall/vi-bg-three.jpg'),
          title: '提升员工敬业度',
          desc: '在弹性福利体系下，相同的成本投入可以满足员工不同的福利需求，使既定成本的有效性大大提高，有效提高员工敬业度，提升员工的企业文化认同感和归属感。'
        },
        {
          bgImg: require('@/assets/img/virtualmall/vi-bg-fore.jpg'),
          title: '技术支持',
          desc: '平台自主开发、迭代升级，拥有成熟的技术管理框架，保障信息数据加密安全管理，提供API、Webservice等多种接口，多语言支持，高可用、高稳定系统；24小时实时监控，实行全方位的安全防护体系和完备的防护响应机制。'
        }
      ],
      stepActiveIndex: 0,
      stepImgList: [
        {
          url: require('@/assets/img/virtualmall/step-one.jpg')
        },
        {
          url: require('@/assets/img/virtualmall/step-two.jpg')
        },
        {
          url: require('@/assets/img/virtualmall/step-three.jpg')
        }
      ]
    };
  },
  mounted() {
    new this.$wow.WOW().init({
      //新建实列
      boxClass: 'wow', //class名字
      animateClass: 'animate__animated', //animateclass动画库类名,和当前animate动画库决定
      offset: 0, //到元素距离触发动画（当默认为0）
      mobile: true, //在移动设备上触发动画（默认为true）
      live: true //对异步加载的内容进行操作（默认为true）
    });
  }
};
</script>

<style lang="less" scoped>
.virtualmall {
  margin-bottom: 100px;
  .header-banner {
    height: 483px;
    width: 100%;
    background-image: url('../../assets/img/virtualmall/vi-logo.png');
    background-size: 100% 100%;
    .title-box {
      padding-top: 99px;
      .banner-one-title,
      .banner-two {
        margin-left: 45px;
      }
      .banner-two {
        margin: 10px 45px 14px 45px;
        .btn {
          width: 117px;
          height: 34px;
          border: 1px solid @lupay-text-color-theme;
          border-radius: 17px;
          margin-right: 20px;
          color: @lupay-text-color-theme;
        }
      }
      .btn-line {
        margin-top: 120px;
        margin-left: 49px;
        border-radius: 6px;
        position: relative;
      }
      .btn-line:hover::after {
        content: '';
        display: block;
        width: 160px;
        height: 160px;
        top: 80px;
        left: -1px;
        position: absolute;
        background-image: url('../../assets/img/lupaywechat.png');
        background-size: 100% 100%;
        padding: 2px;
        border: 1px solid #f4f4f4;
        opacity: 1;
        transition-duration: 300ms;
      }
    }
  }
  &-introduce {
    margin-top: 77px;
    .two-leave-title {
      color: rgba(0, 0, 0, 0.8);
      font-size: 22px;
      margin-left: 49px;
    }
    .content {
      margin-top: 71px;
      height: 277px;
      background: rgba(224, 240, 255, 0.2);
      position: relative;
      padding-top: 45px;
      .left {
        margin-left: 45px;
      }
      .right {
        img {
          top: -48px;
          right: 22px;
          width: 548px;
          height: 301px;
          border-radius: 12px;
          position: absolute;
          transition: all 0.6s;
        }
        img:hover {
          transform: scale(1.1);
          transition: all 0.6s;
        }
      }
    }
  }
  &-ecology {
    margin-top: 77px;
    .two-leave-title {
      color: rgba(0, 0, 0, 0.8);
      font-size: 22px;
      margin-left: 49px;
    }
    .desc {
      width: 720px;
      color: rgba(0, 0, 0, 0.8);
      font-size: 14px;
      margin-top: 45px;
      margin-left: 49px;
    }
  }
  .use-scenarios {
    margin-top: 77px;
    width: 100%;
    height: 507px;
    .scenarios {
      width: 100%;
      height: 100%;
      background-image: url('../../assets/img/virtualmall/use-logo.png');
      background-size: 100% 100%;
    }
    .two-leave-title {
      color: rgba(0, 0, 0, 0.8);
      font-size: 22px;
      margin-left: 49px;
      margin-bottom: 90px;
    }
    .desc {
      width: 463px;
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      margin-left: 49px;
      line-height: 25px;
    }
  }
  .brand {
    margin-top: 50px;
    .two-leave-title {
      color: rgba(0, 0, 0, 0.8);
      font-size: 22px;
      margin-left: 49px;
      margin-bottom: 90px;
    }
  }
  .fore-card {
    margin-top: 88px;
  }
}
</style>
