<template>
  <div class="setps flex">
    <div class="setp">
      <div class="stepItem" v-for="(item, index) in billList" :key="index" @click="onClickBtn(index)">
        <div :class="['crcil', activeIndex == index && 'crcil-selected']"></div>
        <div class="stepBox">
          <div :class="['btn', activeIndex == index && 'btn-selected']">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div v-if="activeIndex == 0">
      <div class="content">
        1、深度结合企业业务与客户系统，提升积分运营价值；<br />
        2、满足标准版本所需功能，提供更完善的定制化服务、丰富的运营工具、促进日活和业务增成等，全面提升商城价值。
      </div>
      <div class="flex info-box">
        <div class="info-btn">高效</div>
        <div class="info-btn">明确</div>
        <div class="info-btn">精准</div>
        <div class="info-btn">稳定</div>
        <div class="info-btn">可用性强</div>
      </div>
    </div>
    <div v-if="activeIndex == 1">
      <div class="content">
        1、项目独立、灵活管理，平台可自主开发、迭代升级，也可提供API、Webservice等多种接口，多语言支持，高可用、高稳定系统，24小时实时监控，快速安全响应；
        <br />
        2、信息数据加密安全管理，前后端数据分离，解决信息储存、处理、传输过程中的数据安全问题；
        <br />
        3、成熟的技术管理框架，确保正常用户登录的同时，拒绝非法程序访问与执行。
      </div>
      <div class="flex info-box">
        <div class="info-btn">成熟</div>
        <div class="info-btn">专业</div>
        <div class="info-btn">安全</div>
        <div class="info-btn">稳定</div>
        <div class="info-btn">扩展性强</div>
      </div>
    </div>
    <div v-if="activeIndex == 2">
      <div class="content">
        1、1对1配备商城运营专员，快速响应需求，及时给出解决方案； <br />
        2、8年活动运营服务经验，为企业用户增长提供完整的营销运营闭环；<br />
        3、电商化运营服务体系，标准QA为用户提供服务咨询，售后无忧；<br />
        4、电商化仓配体系，采用一级物流网络承运商全网覆盖，为用户提供完善的仓储物流服务。
      </div>
      <div class="flex info-box">
        <div class="info-btn">创新</div>
        <div class="info-btn">完善</div>
        <div class="info-btn">灵活</div>
        <div class="info-btn">快速</div>
        <div class="info-btn">持续性强</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeIndex: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      billList: [
        {
          name: '产品打造'
        },
        {
          name: '技术研发'
        },
        {
          name: '运营售后'
        }
      ]
    };
  },
  methods: {
    onClickBtn(index) {
      this.$emit('update:activeIndex', index);
    }
  }
};
</script>

<style scoped lang="less">
.setps {
  .setp {
    width: 132px;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    .stepItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      padding-bottom: 20px;
    }
    .crcil {
      width: 8px;
      height: 8px;
      box-shadow: 3px 3px 6px rgba(255, 255, 255, 1);
      border-radius: 50%;
      z-index: 20;
      border: 1px solid @lupay-text-color-theme;
    }
    .crcil-selected {
      background: @lupay-text-color-theme;
    }
    .stepItem:not(:last-of-type):after {
      content: '';
      height: 39px;
      width: 0.05rem;
      background: @lupay-text-color-theme;
      position: absolute;
      left: 0.2rem;
      top: 17px;
      z-index: 1;
    }
    .stepBox {
      width: 85%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
    }
    .btn {
      height: 26px;
      cursor: pointer;
      line-height: 26px;
      padding: 0 10px;
      transform: translate(0, 0);
      transition-duration: 300ms;
      transition-property: transform, opacity;
      transition-timing-function: ease-in;
    }
    .btn:hover {
      text-align: center;
      background: @lupay-text-color-theme;
      box-shadow: 0px 3px 10px rgba(34, 78, 219, 0.1);
      border-radius: 6px;
      color: #fff;
      transform: translate(0, 0);
    }
    .btn-selected {
      text-align: center;
      background: @lupay-text-color-theme;
      box-shadow: 0px 3px 10px rgba(34, 78, 219, 0.1);
      border-radius: 6px;
      color: #fff;
      transform: translate(0, 0);
    }
  }
  .content {
    width: 380px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    padding-left: 24px;
  }
  .info-box {
    padding-left: 24px;
    margin-top: 20px;
    .info-btn {
      height: 22px;
      padding: 0 10px;
      border: 1px solid @lupay-text-color-theme;
      border-radius: 18px;
      color: @lupay-text-color-theme;
      font-size: 12px;
      text-align: center;
      line-height: 20px;
      margin: 0 12px;
    }
    .info-btn:first-child {
      margin-left: 0;
    }
  }
}
</style>
