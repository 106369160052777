var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"setps flex"},[_c('div',{staticClass:"setp"},_vm._l((_vm.billList),function(item,index){return _c('div',{key:index,staticClass:"stepItem",on:{"click":function($event){return _vm.onClickBtn(index)}}},[_c('div',{class:['crcil', _vm.activeIndex == index && 'crcil-selected']}),_c('div',{staticClass:"stepBox"},[_c('div',{class:['btn', _vm.activeIndex == index && 'btn-selected']},[_vm._v(_vm._s(item.name))])])])}),0),(_vm.activeIndex == 0)?_c('div',[_vm._m(0),_vm._m(1)]):_vm._e(),(_vm.activeIndex == 1)?_c('div',[_vm._m(2),_vm._m(3)]):_vm._e(),(_vm.activeIndex == 2)?_c('div',[_vm._m(4),_vm._m(5)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_vm._v(" 1、深度结合企业业务与客户系统，提升积分运营价值；"),_c('br'),_vm._v(" 2、满足标准版本所需功能，提供更完善的定制化服务、丰富的运营工具、促进日活和业务增成等，全面提升商城价值。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex info-box"},[_c('div',{staticClass:"info-btn"},[_vm._v("高效")]),_c('div',{staticClass:"info-btn"},[_vm._v("明确")]),_c('div',{staticClass:"info-btn"},[_vm._v("精准")]),_c('div',{staticClass:"info-btn"},[_vm._v("稳定")]),_c('div',{staticClass:"info-btn"},[_vm._v("可用性强")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_vm._v(" 1、项目独立、灵活管理，平台可自主开发、迭代升级，也可提供API、Webservice等多种接口，多语言支持，高可用、高稳定系统，24小时实时监控，快速安全响应； "),_c('br'),_vm._v(" 2、信息数据加密安全管理，前后端数据分离，解决信息储存、处理、传输过程中的数据安全问题； "),_c('br'),_vm._v(" 3、成熟的技术管理框架，确保正常用户登录的同时，拒绝非法程序访问与执行。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex info-box"},[_c('div',{staticClass:"info-btn"},[_vm._v("成熟")]),_c('div',{staticClass:"info-btn"},[_vm._v("专业")]),_c('div',{staticClass:"info-btn"},[_vm._v("安全")]),_c('div',{staticClass:"info-btn"},[_vm._v("稳定")]),_c('div',{staticClass:"info-btn"},[_vm._v("扩展性强")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_vm._v(" 1、1对1配备商城运营专员，快速响应需求，及时给出解决方案； "),_c('br'),_vm._v(" 2、8年活动运营服务经验，为企业用户增长提供完整的营销运营闭环；"),_c('br'),_vm._v(" 3、电商化运营服务体系，标准QA为用户提供服务咨询，售后无忧；"),_c('br'),_vm._v(" 4、电商化仓配体系，采用一级物流网络承运商全网覆盖，为用户提供完善的仓储物流服务。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex info-box"},[_c('div',{staticClass:"info-btn"},[_vm._v("创新")]),_c('div',{staticClass:"info-btn"},[_vm._v("完善")]),_c('div',{staticClass:"info-btn"},[_vm._v("灵活")]),_c('div',{staticClass:"info-btn"},[_vm._v("快速")]),_c('div',{staticClass:"info-btn"},[_vm._v("持续性强")])])
}]

export { render, staticRenderFns }